var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c("mew-select", {
        attrs: {
          "has-filter": true,
          label: _vm.$t("ens.request.select-duration"),
          items: _vm.items,
          "normal-dropdown": "",
        },
        on: { input: _vm.setDuration },
      }),
      _c("div", { staticClass: "font-weight-bold text-center" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("ens.request.estimated-price")) +
            ": " +
            _vm._s(_vm.rentPriceETH) +
            " " +
            _vm._s(_vm.$t("common.currency.eth")) +
            " (" +
            _vm._s(_vm.rentPriceUSD) +
            ") "
        ),
      ]),
      _c("div", { staticClass: "d-flex align-center justify-center mt-3" }, [
        _c("div", [
          _vm.noFundsForRenewalFees
            ? _c(
                "span",
                {
                  staticClass:
                    "balance-error d-flex mt-2 mb-3 justify-center align-center",
                },
                [
                  _vm._v(" Not enough balance: "),
                  _c(
                    "a",
                    {
                      staticClass: "link",
                      attrs: { target: "_blank" },
                      on: { click: _vm.openBuySell },
                    },
                    [_c("u", [_vm._v("Buy More Eth")])]
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-center" },
            [
              _c("mew-button", {
                attrs: {
                  loading: _vm.loadingRenew,
                  disabled: _vm.noFundsForRenewalFees || _vm.loadingRenew,
                  title: _vm.$t("ens.renew"),
                  "btn-size": "xlarge",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.renew(_vm.duration)
                  },
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }